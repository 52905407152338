import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { StoryData } from 'storyblok-js-client';
import Layout from '../../sb-components/layout';
import Page from '../../sb-components/page';
import { CommonData, GlobalData } from '../../common-types';
import BlogContext from '../../sb-components/blogContext';
import StoryblokService from '../../utils/storyblok-service';

interface State {
  pageData: CommonData;
  globalData: GlobalData;
  blogs?: any;
  authors?: any;
}

interface Props
  extends PageProps<{
    pageData: StoryData<string>;
    globalData: StoryData<string>;
    blogs?: any;
    authors?: any;
  }> {}

class BlogPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const authorsArray = props.data.authors.edges.map((item: any) => ({
      ...item.node,
    }));
    const authorsDict = Object.assign(
      {},
      ...authorsArray.map((item: any) => ({
        [item.uuid]: { ...item, content: JSON.parse(item.content) },
      }))
    );

    this.state = {
      pageData: JSON.parse(props.data.pageData.content),
      globalData: JSON.parse(props.data.globalData.content),
      blogs: props.data.blogs.edges.map((item: any) => ({
        ...item.node,
        content: JSON.parse(item.node.content),
      })),
      authors: authorsDict,
    };
  }

  async componentDidMount() {
    try {
      const response = await StoryblokService.get(
        `cdn/stories/?starts_with=blog`
      );

      const newPostItems = response?.data?.stories?.filter(
        (a: any) => a.content.component === 'post'
      );

      const newPageItems = response?.data?.stories?.filter(
        (a: any) => a?.content?.component === 'page'
      );

      if (newPostItems && newPostItems?.length) {
        this.setState((prevState) => ({
          ...prevState,
          blogs: [...Object.values(newPostItems)],
          pageData: newPageItems[0]?.content || prevState.pageData,
        }));
      }
      setTimeout(() => StoryblokService.initEditor(this), 200);
      this.setState((prevState) => ({
        ...prevState,
      }));
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      this.setState((prevState) => ({
        ...prevState,
      }));
    }
  }

  render() {
    return (
      <BlogContext.Provider
        value={{ blogs: this.state.blogs, authors: this.state.authors }}
      >
        <Layout
          className="page page-blog"
          header={this.state.globalData.header}
          footer={this.state.globalData.footer}
          showBlock
          cookiesConsent={this.state.globalData.cookiesConsent}
          full_slug={this.props.data.pageData?.full_slug}
        >
          <Page
            blok={{
              ...this.state.pageData,
            }}
          />
        </Layout>
      </BlogContext.Provider>
    );
  }
}

export default BlogPage;

export const query = graphql`
  {
    pageData: storyblokEntry(slug: { eq: "blog" }) {
      name
      content
      full_slug
      first_published_at
      uuid
    }
    globalData: storyblokEntry(full_slug: { eq: "global" }) {
      name
      content
      full_slug
      first_published_at
      uuid
    }
    blogs: allStoryblokEntry(filter: { field_component: { eq: "post" } }) {
      edges {
        node {
          id
          name
          slug
          field_component
          full_slug
          first_published_at
          content
          tag_list
          uuid
        }
      }
    }
    authors: allStoryblokEntry(filter: { field_component: { eq: "author" } }) {
      edges {
        node {
          id
          full_slug
          first_published_at
          uuid
          content
          name
        }
      }
    }
  }
`;
